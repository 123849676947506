if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'waypoints/lib/noframework.waypoints.min.js';
import Player from '@vimeo/player';

window.onload = function() {
  setTimeout(function(){$('body').removeClass('prevent-transition');}, 100);
}

$(document).ready(function(){

  let ieAgent = !!window.MSInputMethodContext && !!document.documentMode;;
  if(ieAgent){
    $('html').addClass('IE11')
  }

  var navDropdown = new Waypoint({
    element: $('.navtrigger')[0],
    handler: function(direction) {
      if(direction == 'down'){
        $('header').addClass('trigger');
      } else {
        $('header').removeClass('trigger');
      }
    },
    offset: '10%'
  })

  var heroVideo = new Player($('.hero-section .video-holder iframe')[0]);

  heroVideo.on('bufferend',function(){
    $('.hero-section .video-holder').addClass('video-loaded');
  });

  $('.hero-section').click(function(){
    if(!$('.hero-section').hasClass('video-show')){
      $('.hero-section').addClass('video-show');
      heroVideo.play();
    }
  })

  $('.popup-button').click(function(){
    $('.overlay').addClass('show');
  });

  $('.overlay .close').click(function(){
    $('.overlay').removeClass('show');
  })

  $('.contact_quickscroll').click(function(){
    window.scrollTo({top: document.querySelector('.color-swoops-2').getBoundingClientRect().top + window.pageYOffset - 40, behavior: 'smooth'});
  })

  $('.back-to-top').click(function(){
    window.scroll({
     top: 0,
     left: 0,
     behavior: 'smooth'
    });
  })

  $('.email-button').click(function(){
    var mail = document.createElement("a");
    mail.href = "mailto:ask@peoplescience.health?subject=I%20want%20to%20advance%20the%20science%20of%20alternative%20medicine!&body=Hello%2C%20I%20am%20interested%20in%20learning%20more%20about%20People%20Science.%20Please%20contact%20me%20so%20we%20can%20discuss%20further.%0D%0A%0D%0AThank%20you%2C";
    mail.target = '_blank';
    mail.click();
  })

  if(getCookie('PSCookieAccept')){
    $('.cookie-banner').hide();
  }

  $('.cookie-banner button').click(function(){
    document.cookie = "PSCookieAccept=1; path=/";
    $('.cookie-banner').hide();
  })

  // select the target node
  var target = document.getElementById('mce-success-response');

  // create an observer instance
  var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (target.style.display === 'block') {
        $('.overlay-body').addClass('show-message');
        dataLayer.push({
          'event' : 'subscribe_form_submitted'
        });
      }
    });
  });

  // configuration of the observer:
  var config = { attributes: true, childList: true, characterData: true };

  // pass in the target node, as well as the observer options
  observer.observe(target, config);
})


function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}
